<template>
  <b-row>
    <b-col>
      <b-form
        class="mt-2"
        @submit.prevent
        @submit="createManufacturing(data)"
      >
        <b-tabs
          horizontal
          pills
          content-class="col-12 mt-1 mt-md-0"
          nav-wrapper-class="col-12"
          nav-class="nav-left"
        >
          <b-tab active>
            <template #title>
              <feather-icon
                icon="FileTextIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Étape 1</span>
            </template>
            <manufacturing-step-1 :data="data" />
          </b-tab>
          <b-tab
            @activate-tab="ValidationForm()"
          >
            <template #title>
              <feather-icon
                icon="ActivityIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Étape 2</span>
            </template>
            <manufacturing-step-2 :data="data" />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon
                icon="BluetoothIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Étape 3</span>
            </template>
            <manufacturing-step-3 :data="data" />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon
                icon="UsersIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Étape 4</span>
            </template>
            <manufacturing-step-4 :data="data" />
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon
                icon="FilePlusIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Étape 5</span>
            </template>
            <manufacturing-step-5 :data="data" />
          </b-tab>
        </b-tabs>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mt-2 mr-1"
          >
            Sauvegarder
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            class="mt-2"
            :to="{ name: 'manufacturings' }"
          >
            Annuler
          </b-button>
        </b-col>
      </b-form>
    </b-col>
  </b-row>

</template>

<script>
import {
  BTabs, BTab, BRow, BCol, BButton, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ManufacturingStep1 from './ManufacturingStep1.vue'
import ManufacturingStep2 from './ManufacturingStep2.vue'
import ManufacturingStep3 from './ManufacturingStep3.vue'
import ManufacturingStep4 from './ManufacturingStep4.vue'
import ManufacturingStep5 from './ManufacturingStep5.vue'
/* eslint no-underscore-dangle: 0 */
/* eslint no-param-reassign: 0 */

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BButton,
    BForm,
    ManufacturingStep1,
    ManufacturingStep2,
    ManufacturingStep3,
    ManufacturingStep4,
    ManufacturingStep5,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {},
    }
  },
  methods: {
    createManufacturing(data) {
      store.dispatch('manufacturing/createManufacturing', data)
        .then(() => {
          this.data = {}
          this.$router.push({ name: 'manufacturings' })
        })
    },
  },
}
</script>
